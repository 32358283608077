<template>
	<div class="h-100 dis-flex-com ft-sz-14 po-r">
		<div class="">
			<van-swipe class="my-swipe" :autoplay="3000" indicator-color="white">
			  <van-swipe-item v-for="(item,index) in imgUrls" :key="index">
				  <img v-if="item.t == 1" :src="item.filearray" alt="">
				  <img v-else :src="'http://file.zmiti.com/index/files/file?p=' +item.filearray" alt="">
			  </van-swipe-item>
			</van-swipe>
		</div>
		<div class="pd-x-10 pd-t-15 pd-b-15 dis-flex-row al-c bd-bott">
			<div>
				当前单位： <span class="ft-co-fd">{{companyinfo.companyname}}</span>
			</div>
			<div class="pd-x-10 bd-r-50 bk-co-fd ft-co-ff mg-l-auto" v-if="company_list != ''" @click="switchCompanyList(true)">
				切换
			</div>
		</div>
		<div class="pd-b-10 pd-t-10 dis-flex-row al-c bd-bott home-gonggaodiv" v-if="noticeData != ''">
			<div class="pd-x-10 home-gonggao ft-sz-18 ft-w-b" style="font-style: oblique;">公告</div>
			<div class="pd-x-10" style="overflow: hidden;">
				<van-swipe :autoplay="2000" style="width: 100%;" indicator-color="white" :show-indicators="false">
				  <van-swipe-item v-for="(item,index) in noticeData" :key="index">
					  <div class="my-swipe1 ov-fl-h" v-html="item.title"></div>
				  </van-swipe-item>
				</van-swipe>
			</div>
		</div>
		<div class="pd-t-10 pd-b-10 dis-flex-row bk-co-f5" v-if="product_list != ''">
			<div class="flx-1" style="white-space: nowrap;overflow-y: auto;">
				<div class="pd-x-5" v-for="(item,index) in product_list" :key="item.productid" style="display: inline-block;" @click="productJinRu(item)">
					<div class="txt-c bk-co-ff dis-flex-com ju-c productList bd-r-10">
						<div>
							<div>
								<span class="zmt_iconfont ft-sz-38">{{encodeIcon(item)}}</span>
							</div>
							<div class="ft-sz-16 pd-t-5">{{item.productname}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="informationsData != ''">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott">
				<div class="dis-flex-row al-c">
					<span class="zmt_iconfont ft-sz-22">&#xe69a;</span>
					<span class="pd-x-5">新闻动态</span>
				</div>
				<div class="mg-l-auto ft-co-99" @click="$router.push('/information')">
					<span class="pd-x-5">更多</span>
					<van-icon name="arrow" />
				</div>
			</div>
			<!-- 新闻列表 -->
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott" v-for="(item,index) in informationsData" :key="index" @click="detailInfo(item)">
				<div class="mg-r-10">
					<div class="ft-le-10">{{item.title}}</div>
					<div class="pd-t-5 ft-sz-12 ft-co-99">{{util.formatDate('cn',item.createtime)}}</div>
				</div>
				<div class="mg-l-auto" v-if="item.filearray != ''">
					<van-image
					  width="2.3rem"
					  fit="contain"
					  :src="'http://file.zmiti.com/index/files/file?p=' + item.filearray"
					/>
				</div>
			</div>
			<div class="pd-t-10 bk-co-f5"></div>
		</div>
		<div v-if="partyData != ''">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott">
				<div class="dis-flex-row al-c">
					<span class="zmt_iconfont ft-sz-22">&#xe69b;</span>
					<span class="pd-x-5">活动聚</span>
				</div>
				<div class="mg-l-auto ft-co-99">
					<span class="pd-x-5">更多</span>
					<van-icon name="arrow" />
				</div>
			</div>
			<div class="pd-t-10 pd-b-10 dis-flex-row">
				<div class="flx-1" style="white-space: nowrap;overflow-y: auto;">
					<div class="pd-x-5" v-for="(item,index) in partyData" :key="index" style="display: inline-block;">
						<div class="txt-c bk-co-ff dis-flex-com ju-c activityList bd-r-5">
							<div class="flx-1 dis-flex-com">
								<div class="activityimg w-100 bd-r-5 po-r">
									<img class="w-100 po-a"style="top: 0;left: 0;" :src="item.partyurl" alt="">
									<div class="po-a ft-co-ff ft-sz-12 bk-co-39 activityjinx">{{item.datestatus == '2' ?'进行中':'未开始'}}</div>
									<div class="po-a w-100" style="bottom: 0;left: 0;background-color: rgba(0,0,0,.3);">
										<div class="pd-x-10 pd-t-5 pd-b-5 txt-l ft-sz-12 ft-co-ff ov-fl-h" style="-webkit-line-clamp: 1 !important;">共有{{item.totalnumber}}人参与</div>
									</div>
								</div>
								<div class="dis-flex-row al-c">
									<div class="mg-r-auto txt-l">
										<div class="pd-t-5 ov-fl-h" style="-webkit-line-clamp: 1 !important;">{{item.partyname}}</div>
										<div class="ft-sz-12 ft-co-99">截至:{{util.formatDate(' ',item.enteredtime)}}</div>
									</div>
									<div class="pd-x-10">
										<div class="ft-co-ff ft-sz-12 bk-co-39 pd-x-15 pd-t-5 pd-b-5 bd-r-30 ">去参加</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pd-t-10 bk-co-f5"></div>
		</div>
		<div v-if="false">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott">
				<div class="dis-flex-row al-c">
					<span class="zmt_iconfont ft-sz-22">&#xe6b1;</span>
					<span class="pd-x-5">会议通</span>
				</div>
				<div class="mg-l-auto ft-co-99">
					<span class="pd-x-5">更多</span>
					<van-icon name="arrow" />
				</div>
			</div>
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row bk-co-f5">
				<div class="txt-c reservation dis-flex-com ju-c bk-co-ff bd-r-5">
					<div>
						<img src="../../../assets/image/pinglun.png" alt="">
						<div class="">
							<span class="zmt_iconfont ft-sz-18">&#xe6b5;</span>
							<span class="mg-l-5">预定会议室</span>
						</div>
					</div>
				</div>
				<div class="mg-l-10 flx-1 bk-co-ff bd-r-5 dis-flex-com">
					<div class="flx-1 bk-co-39">
						
					</div>
					<div class="flx-1">
						
					</div>
				</div>
			</div>
			<div class="pd-t-10 bk-co-f5"></div>
		</div>
		<div v-if="false">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott">
				<div class="dis-flex-row al-c">
					<span class="zmt_iconfont ft-sz-22">&#xe6b2;</span>
					<span class="pd-x-5">意见箱</span>
				</div>
				<div class="mg-l-auto ft-co-99">
					<span class="pd-x-5">更多</span>
					<van-icon name="arrow" />
				</div>
			</div>
			<div>
				<div class="bd-bott" v-for="i in 3">
					<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row">
						<van-image
						  width="1rem"
						  height="1rem"
						  fit="cover"
						  src="https://img.yzcdn.cn/vant/cat.jpeg"
						/>
						<div class="mg-l-10">
							<div>张泽鹏</div>
							<div class="ft-sz-12 ft-co-99 pd-t-5">所在部门:开发测试专</div>
						</div>
					</div>
					<div class="pd-x-10 ov-fl-h ft-le-10">
						<span class="pd-x-10"></span>静安寺看不到卡布达看见爱上打不开节哀顺变的框架奥比岛快进啊百世快递骄傲保税科技第八课时间吧 
					</div>
					<!-- 一张照片 -->
					<!-- <div class="pd-x-10 pd-t-10 pd-b-10">
						<div class="opinion-img1 po-r">
							<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
						</div>
					</div> -->
					<!-- 两张照片 -->
					<!-- <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row">
						<div class="opinion-img1 po-r flx-1">
							<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
						</div>
						<div class="opinion-img1 po-r mg-l-10 flx-1">
							<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
						</div>
					</div> -->
					<!-- 三张 -->
					<!-- <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row">
						<div class="opinion-img2 po-r flx-1">
							<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
						</div>
						<div class="opinion-img2 po-r mg-l-10 flx-1">
							<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
						</div>
						<div class="opinion-img2 po-r mg-l-10 flx-1">
							<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
						</div>
					</div> -->
					<!-- 四张 -->
					<!-- <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row">
						<div class="opinion-img3 po-r flx-1">
							<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
						</div>
						<div class="opinion-img3 po-r mg-l-10 flx-1">
							<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
						</div>
						<div class="opinion-img3 po-r mg-l-10 flx-1">
							<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
						</div>
						<div class="opinion-img3 po-r mg-l-10 flx-1">
							<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
						</div>
					</div> -->
					<div class="pd-x-10 pd-t-10 pd-b-10">
						<div class="dis-flex-row">
							<div class="opinion-img1 po-r flx-1">
								<!-- <img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt=""> -->
							</div>
							<div class="opinion-img1 po-r mg-l-10 flx-1">
								<!-- <img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt=""> -->
							</div>
						</div>
						<div class="dis-flex-row mg-t-10">
							<div class="opinion-img2 po-r flx-1">
								<!-- <img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt=""> -->
							</div>
							<div class="opinion-img2 po-r mg-l-10 flx-1">
								<!-- <img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt=""> -->
							</div>
							<div class="opinion-img2 po-r mg-l-10 flx-1">
								<!-- <img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt=""> -->
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="pd-t-10 bk-co-f5"></div>
		</div>
		<div v-if="false">
			<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row al-c bd-bott">
				<div class="dis-flex-row al-c">
					<span class="zmt_iconfont ft-sz-22">&#xe6b5;</span>
					<span class="pd-x-5">分享会</span>
				</div>
				<div class="mg-l-auto ft-co-99">
					<span class="pd-x-5">更多</span>
					<van-icon name="arrow" />
				</div>
			</div>
			<div class="bd-bott" v-for="i in 3">
				<div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row">
					<van-image
					  width="1rem"
					  height="1rem"
					  fit="cover"
					  src="https://img.yzcdn.cn/vant/cat.jpeg"
					/>
					<div class="mg-l-10">
						<div>张泽鹏</div>
						<div class="ft-sz-12 ft-co-99 pd-t-5">所在部门:开发测试专</div>
					</div>
				</div>
				<div class="pd-x-10 ov-fl-h ft-le-10">
					<span class="pd-x-10"></span>静安寺看不到卡布达看见爱上打不开节哀顺变的框架奥比岛快进啊百世快递骄傲保税科技第八课时间吧 
				</div>
				<!-- 一张照片 -->
				<!-- <div class="pd-x-10 pd-t-10 pd-b-10">
					<div class="opinion-img1 po-r">
						<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
					</div>
				</div> -->
				<!-- 两张照片 -->
				<!-- <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row">
					<div class="opinion-img1 po-r flx-1">
						<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
					</div>
					<div class="opinion-img1 po-r mg-l-10 flx-1">
						<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
					</div>
				</div> -->
				<!-- 三张 -->
				<!-- <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row">
					<div class="opinion-img2 po-r flx-1">
						<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
					</div>
					<div class="opinion-img2 po-r mg-l-10 flx-1">
						<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
					</div>
					<div class="opinion-img2 po-r mg-l-10 flx-1">
						<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
					</div>
				</div> -->
				<!-- 四张 -->
				<!-- <div class="pd-x-10 pd-t-10 pd-b-10 dis-flex-row">
					<div class="opinion-img3 po-r flx-1">
						<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
					</div>
					<div class="opinion-img3 po-r mg-l-10 flx-1">
						<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
					</div>
					<div class="opinion-img3 po-r mg-l-10 flx-1">
						<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
					</div>
					<div class="opinion-img3 po-r mg-l-10 flx-1">
						<img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt="">
					</div>
				</div> -->
				<div class="pd-x-10 pd-t-10 pd-b-10">
					<div class="dis-flex-row">
						<div class="opinion-img1 po-r flx-1">
							<!-- <img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt=""> -->
						</div>
						<div class="opinion-img1 po-r mg-l-10 flx-1">
							<!-- <img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt=""> -->
						</div>
					</div>
					<div class="dis-flex-row mg-t-10">
						<div class="opinion-img2 po-r flx-1">
							<!-- <img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt=""> -->
						</div>
						<div class="opinion-img2 po-r mg-l-10 flx-1">
							<!-- <img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt=""> -->
						</div>
						<div class="opinion-img2 po-r mg-l-10 flx-1">
							<!-- <img class="po-a bd-r-10 w-100" style="left: 0;top: 0;" src="../../../../new/lottery/assets/images/bg.jpg" alt=""> -->
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="ft-sz-14 ft-co-99 txt-c pd-y-15">别划了,到底了</div>
		<div class="tabbarheight"></div>
		<van-tabbar v-model="active" active-color="#ee0a24" inactive-color="#000" @change="activeCheng">
		  <van-tabbar-item>
		    <span>精选</span>
		    <template #icon="props">
		      <span class="zmt_iconfont ft-sz-22">&#xe6b6;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>资讯</span>
		    <template #icon="props">
		      <span class="zmt_iconfont ft-sz-22">&#xe6ae;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>服务</span>
		    <template #icon="props">
		      <span class="zmt_iconfont ft-sz-22">&#xe6b0;</span>
		    </template>
		  </van-tabbar-item>
		  <van-tabbar-item>
		    <span>我的</span>
		    <template #icon="props">
		      <span class="zmt_iconfont ft-sz-22">&#xe6ac;</span>
		    </template>
		  </van-tabbar-item>
		</van-tabbar>
		<van-overlay :show="companyListStatus" z-index="9999" @click="show = false">
		  <div class="h-100 bk-co-ff login">
			  <div class="wrapper bk-co-ff" @click.stop>
			    <CompanyList :company_list="company_list"></CompanyList>
			  </div>
		  </div>
		</van-overlay>
	</div>
</template>
<style scoped>
	 .my-swipe .van-swipe-item img{width: 100%;height: 180px;}
	 .my-swipe1{width: 100%;}
	  .home-gonggaodiv{min-height: 48px;}
	  .home-gonggao{width: 16px;border-right: 1px solid #F5F5F5;}
	  .productList{width: 94px;height: 94px;}
	  .activityList{width: 220px;}
	  .activityimg{height: 120px;overflow: hidden;}
	  .activityjinx{top: 0;right: 0;padding: 5px 15px;border-bottom-left-radius: 10px;}
	  .reservation{width: 120px;height: 120px;}
	  .reservation img{width: 68px;height: 68px;}
	  .opinion-img1{height: 150px;overflow: hidden;border-radius: 10px;}
	  .opinion-img2{height: 86px;overflow: hidden;border-radius: 10px;}
	  .opinion-img3{height: 75px;overflow: hidden;border-radius: 10px;}
	  .login {
	  	padding: 60px 48px 0 48px;
	  }
	  button {border: none;color: #FFFFFF;border-radius: 30px;background: -webkit-linear-gradient(#f7514d, #fd704d);}
	  button:focus {outline: none !important;}
</style>
<script>
	import http from '../../lib/api.js'
	import util from '../../lib/util.js'
	import CompanyList from '../../components/companyList.vue';
	import {
		Toast
	} from 'vant';
	var {
		adminGeneral
	} = http
	const normalImgUrls=[{
	   filearray:'http://m.zmiti.com/images/banner1.jpg',
	   t:1
	},{
	   filearray:'http://m.zmiti.com/images/banner2.jpg',
	   t:1
	},{
	   filearray:'http://m.zmiti.com/images/banner3.jpg',
	   t:1
	},{
	   filearray:'http://m.zmiti.com/images/banner4.jpg',
	   t:1
	},{
	   filearray:'http://m.zmiti.com/images/banner5.jpg',
	   t:1
	}]
	var he = require('he');
	export default {
		name: 'Home',
		inject:['reload'],
		components: {CompanyList},
		data() {
			return {
				zmitiImgUrls:[],
				active:0,
				util:util,
				company_list:[],/*公司列表*/
				companyListStatus:false,
				companyinfo:{},/*公司信息*/
				product_list:[],
				iconData:[
				  'http://m.zmiti.com/images/zmt_tushushe.png'
				],
				piColumn:0,//图片新闻
				imgUrls: normalImgUrls,
				current:0,
				productData:[],
				infoData:[],//信息管理首页栏目
				informationProid:0,
				specialnum0:-1,//最新消息
				lastnewData:[],//最新消息内容
				specialnum1:[{
					infotypeid:'',
					typename:''
				}],//互动区
				commentData:[],//互动台全部数据
				specialnum3:0,//公告栏目
				noticeData:[],//公告栏目下的列表内容
				messageColumn:'',//意见箱
				messageData:[],//意见箱列表内容
				partyProid:0,//活动产品id
				partyData:[],//活动列表内容
				voteProid:0,//投票产品id
				voteData:[],//投票列表内容
				questionProid:0,//调查产品id
				questionData:[],//调查列表内容
				meetProid:0,//会议产品id
				meetData:[],//会议室列表内容
				meetingdataSource:[],
				isLoading: false,
				currentcompany:{},
				login:{},
				column_first:false,//消息是否显示
				column_second:false,//公告是否显示
				currentIndex:0,
				menuiconData:[],
				menuiconDataA:[],
				menuiconDataB:[],
				specialnum4:0,//资料新闻
				informationsData:[],
				informationsName:'',
				curentDate:parseInt(Number(new Date())/1000)
			}
		},
		created(){},
		mounted() {
			if(this.$store.state.companyid == '' && this.$store.state.user.userid != ''){
				this.companyList();
				this.pcompanyList();
			}else{
				this.userInfo();
			}
		},
		activated() {},
		methods: {
			userInfo() {
				var s = this;
				adminGeneral.userInfo.http({
					"companyid": s.$store.state.companyid,
					"userid": s.$store.state.user.userid,
				}).then(res => {
					if (res.getret === 0) {
						s.companyList();
						s.pcompanyList();
					} else if (res.getret === 9997) {
						s.$router.push('/login')
					} else {
						s.$router.push('/login')
					}
				})
			},
			pcompanyList(){/*用户获取公司已授权产品列表*/
				var _this = this;
				adminGeneral.userProductList.http({
					"condition":{
						"companyid": _this.$store.state.companyid,
						"page_index":0,
						"page_size":100
					}
				}).then(res => {
					if (res.getret === 0) {
						_this.product_list = res.list
						
						res.list.map((item,index)=>{
							switch(item.producturl){        		
								case '/information/index':{
									/*资讯窗产品id*/
						            _this.informationProid = item.productid
									_this.$store.commit('setProductid',item.productid)
						            /*获取在首页显示栏目*/
						            _this.informationTypeList(item.productid);
									break;
								}
								case '/activity/party':{
										/*活动管理*/
										_this.partyProid = item.productid;
										_this.voteProid = item.productid;
										_this.questionProid = item.productid;
										/*获取活动列表*/
										_this.activityList(item.productid);
										/*获取投票列表*/
										_this.userVoteList(item.productid);
										/*获取调查列表*/
										_this.userSurveyList(item.productid);
									break;
								}
								case '/interact/index':{
									/*互动台产品id*/
									_this.interactProid = item.productid
									// _this.informationTypeList(item.productid);
									break;
								}
								case '/businesstrip/index':{
									
									break;
								}
								case '/meeting/index':{
						            /*会议通*/
						            // _this.setState({
						            //   meetProid:item.productid
						            // },function(){
						            //   /*获取会议列表*/
						            //   _this.getmeetingDataList(item.productid);
						            //   _this.getmeetingsystemDataList(item.productid);
						            // })
									break;
								}
								case '/diycompany/duty':{
									break;
								}
								default:{
									break;
								}
							}
						})	 
					}
				})
			},
			switchCompanyList(val){/*切换单位*/
				this.companyListStatus = val?true:false;
				if(!val){
					this.reload();
				}
			},
			companyList(){/*用户握手获取公司列表*/
				var s = this;
				adminGeneral.shakeHands.http({}).then(res => {
					if (res.getret === 0) {
						if(res.info.company_list.length > 1){
							var companyinfo = res.info.company_list.filter(item=>{
								return item.companyid == s.$store.state.companyid
							})
							s.companyinfo = JSON.parse(JSON.stringify(companyinfo))[0];
						}else if(res.info.company_list.length == 1){
							s.$store.commit('setCompanyid',res.info.company_list[0].companyid)
						}
						s.company_list = res.info.company_list
					}
				})
			},
			informationTypeList(productid){/*获取用户信息类型列表 2001304*/
				var s = this;
				
				adminGeneral.userInformationTypeList.http({
					"condition":{
						"companyid":s.$store.state.companyid,
						"isshowindex": 1,
						"page_index": 0,
						"page_size": 100,
						"productid": productid,
					}
				}).then(data => {
					if (data.getret === 0) {
						if(data.list.length>0){
						  let newtypeData=[]
						  /*按时间排序*/
						  newtypeData=data.list.sort(util.objectArraySort('createtime'))
						  newtypeData.map((item,index)=>{
						    if(item.specialnum==0 && index==0){//消息类型
						      s.column_first = true
						    }
						  })
						  s.infoData = data.list
						  /*当有最新消息时*/
						  var specialnum0=data.list.filter(item=>item.specialnum==0)
						  if(specialnum0){
						    s.specialnum0 = specialnum0[0].infotypeid;
						    /*获取消息内容*/
						    s.getNewsList(specialnum0[0].infotypeid,1,function(value){
								// console.log('消息内容','lastnewData',value)
								s.lastnewData = value;
							})
						  }
						  /*当有公告栏目时*/
						  var specialnum3=data.list.filter(item=>item.specialnum==3)     
						  if(specialnum3){//公告
						    /*获取第1个公告的infotypeid*/
						    s.specialnum3 = specialnum3[0].infotypeid
						    /*获取公告列表*/
						    s.getNewsList(specialnum3[0].infotypeid,3,function(value){
								// console.log('公告','noticeData',value)
								s.noticeData = value;
								s.column_second = true;
							})
						  }
						  /*当有资料新闻栏目时*/
						  var specialnum4=data.list.filter(item=>item.specialnum==4)     
						  if(specialnum4){//资料新闻            
						    /*获取资料新闻列表*/
						    s.getNewsList(specialnum4[0].infotypeid,4,function(value){
								// console.log('资料新闻列表','informationsData',value)
								s.informationsData = value;
								s.specialnum4 = specialnum4[0].infotypeid;/*获取第1个资料新闻的infotypeid*/
								s.informationsName = specialnum4[0].typename;       
							})
						  }
						  /*当其他类型有图片栏目时*/
						  var piColumn=data.list.filter(item=>item.specialnum==5)
						  if(piColumn){//图片新闻
						    s.piColumn = piColumn[0].infotypeid;
						    /*获取图片新闻列表 轮播*/
						    s.getNewsList(piColumn[0].infotypeid,10,function(value){
								console.log('图片新闻列表','imgUrls',value)
								if(value != ''){
									s.imgUrls = value;
								}
							})
						  }else{
						    s.zmitiImgUrls = normalImgUrls
						  }  
						}else{
						  s.zmitiImgUrls = normalImgUrls
						}
					}
				})
			},
			activityList(productid){/*获取活动列表 2001707*/
				var s = this;
				adminGeneral.activityList.http({
					"condition":{
						"companyid":s.$store.state.companyid,
						"productid":productid,
						"page_index":0,
						"page_size":5,
						"status":1,
						"orderby":'begintime',
						"sort":'desc'
					}
				}).then(data => {
					if (data.getret === 0) {
						if(data.total>0){
						  let partyNewdata=[];
						  //1未开始2进行中0已结束
						  data.list.map((item,index)=>{
							if(s.curentDate<item.begintime){
							  partyNewdata.push({
								partyid:item.partyid,
								companyid:item.companyid,
								partyname:item.partyname,
								partyurl:item.partyurl,
								abstract:item.abstract,
								begintime:item.begintime,
								enteredtime:item.enteredtime,
								status:item.status,
								totalnumber:item.totalnumber,
								datestatus:1
							  })
							}else if(s.curentDate<item.enteredtime && s.curentDate>item.begintime){
							  partyNewdata.push({
								partyid:item.partyid,
								companyid:item.companyid,
								partyname:item.partyname,
								partyurl:item.partyurl,
								abstract:item.abstract,
								begintime:item.begintime,
								enteredtime:item.enteredtime,
								status:item.status,
								totalnumber:item.totalnumber,
								datestatus:2
							  })
							}
						  })
						  /*活动按时间状态排序*/
						  // partyNewdata=partyNewdata.sort(util.objectArraySort('datestatus'))
						  console.log(partyNewdata,'partyNewdata')
						  s.partyData = partyNewdata;//data.list
						}
					}
				})
			},
			userVoteList(productid){/*用户获取投票列表 20014030*/
				var s = this;
				adminGeneral.userVoteList.http({
					"condition":{
						"companyid":s.$store.state.companyid,
						"productid":productid,
						"page_index":0,
						"page_size":5,
						"status":1
					}
				}).then(data => {
					if (data.getret === 0) {
						if(data.total>0){
						  let voteNewdata=[];
						  //1未开始2进行中0已结束
						  data.list.map((item,index)=>{
						    if(s.curentDate<item.begintime){
						      voteNewdata.push({
						        voteid: item.voteid,
						        companyid: item.companyid,
						        votetitle: item.votetitle,
						        abstract: item.abstract,
						        isrealname: item.isrealname,
						        voteurl: item.voteurl,
						        begintime: item.begintime,
						        endtime: item.endtime,
						        createtime: item.createtime,
						        updatetime: item.updatetime,
						        status: item.status,
						        votenumber: item.votenumber,
						        vuid: item.vuid,
						        totalnumbers: item.totalnumbers,
						        datestatus:2
						      })
						    }else if(s.curentDate<item.endtime && s.curentDate>item.begintime){
						      voteNewdata.push({
						        voteid: item.voteid,
						        companyid: item.companyid,
						        votetitle: item.votetitle,
						        abstract: item.abstract,
						        isrealname: item.isrealname,
						        voteurl: item.voteurl,
						        begintime: item.begintime,
						        endtime: item.endtime,
						        createtime: item.createtime,
						        updatetime: item.updatetime,
						        status: item.status,
						        votenumber: item.votenumber,
						        vuid: item.vuid,
						        totalnumbers: item.totalnumbers,
						        datestatus:2
						      })
						    }
						  })
						 s.voteData = voteNewdata//data.list
						}
					}
				})
			},
			userSurveyList(productid){/*用户获取调查列表 2001530*/
				var s = this;
				adminGeneral.userSurveyList.http({
					"condition":{
						"companyid":s.$store.state.companyid,
						"productid":productid,
						"page_index":0,
						"page_size":5
					}
				}).then(data => {
					if (data.getret === 0) {
						if(data.total>0){
						  let questionNewdata=[];
						  //1未开始2进行中0已结束
						  data.list.map((item,index)=>{
						    if(s.curentDate<item.begintime){
						      questionNewdata.push({
						        inquireid: item.inquireid,
						        companyid: item.companyid,
						        inquiretitle: item.inquiretitle,
						        abstract: item.abstract,
						        isrealname: item.isrealname,
						        begintime: item.begintime,
						        endtime: item.endtime,
						        createtime: item.createtime,
						        updatetime: item.updatetime,
						        status: item.status,
						        type: item.type,
						        inquire_url: item.inquire_url,
						        vuid: item.vuid,
						        totalnumbers: item.totalnumbers,
						        datestatus:1
						      })
						    }else if(s.curentDate<item.endtime && s.curentDate>item.begintime){
						      questionNewdata.push({
						        inquireid: item.inquireid,
						        companyid: item.companyid,
						        inquiretitle: item.inquiretitle,
						        abstract: item.abstract,
						        isrealname: item.isrealname,
						        begintime: item.begintime,
						        endtime: item.endtime,
						        createtime: item.createtime,
						        updatetime: item.updatetime,
						        status: item.status,
						        type: item.type,
						        inquire_url: item.inquire_url,
						        vuid: item.vuid,
						        totalnumbers: item.totalnumbers,
						        datestatus:2
						      })
						    }
						  })
						  s.questionData = questionNewdata//data.list
						}
					}
				})
			},
			getNewsList(infotypeid,limit,fun){/*获取查看列表 2001319*/
				var s = this;
				adminGeneral.toViewList.http({
					"condition":{
						"companyid":s.$store.state.companyid,
						"productid":s.informationProid,
						"infotypeid":infotypeid,
						"status":2,//通过审核的
						"page_index":0,
						"page_size":limit,
						"newtype":'资讯窗'
					}
				}).then(res => {
					if (res.getret === 0) {
						fun(res.list)
					}
				})
			},
			encodeIcon(val){
				if(val.productid == 1166396962){
					return he.unescape('&#xe6b1;')
				}else if(val.productid == 4674349500){
					return he.unescape('&#xe697;')
				}else{
					return he.unescape(val.icon)
				}
			},
			productJinRu(val){
				//console.log(val)
				if(val.producturl == '/information/index'){
					this.$router.push('/information')
				}else{
					this.$router.push('/service')
				}
			},
			activeCheng(e){
				if(e == 1){
					this.$router.push('/information')
				}else if(e==2){
					this.$router.push('/service')
				}else if(e==3){
					this.$router.push('/my')
				}
			},
			detailInfo(val){/*资讯详情*/
				var s = this;
				this.$router.push('/detail/' + val.infoid+'/'+s.$store.state.companyid+'/'+s.informationProid);
			},
		},
		destroyed() {}
	}
</script>
