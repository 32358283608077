import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  user:{
		  token:!!JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).token || '',
		  userid:!!JSON.parse(localStorage.getItem('user')) && JSON.parse(localStorage.getItem('user')).userid || ''
	  },
	  productid:JSON.parse(localStorage.getItem('productid')) || '',/*产品id*/
	  companyid:JSON.parse(localStorage.getItem('companyid')) || '',/*公司id*/
	  informationTab:JSON.parse(localStorage.getItem('informationTab')) || 0,/*资讯Tab选项*/
  },
  getters:{
	  
  },
  mutations: {
	  setToken(state,payload){
		  state.user.token = payload.token;
		  state.user.userid = payload.userid;
	  },
	  setCompanyid(state,payload){
		  localStorage.setItem('user',JSON.stringify(state.user))
		  localStorage.setItem('companyid',JSON.stringify(payload))
		  state.companyid = payload;
	  },
	  setProductid(state,payload){
	  		  localStorage.setItem('productid',JSON.stringify(payload))
	  		  state.productid = payload;
	  },
	  setInformationTab(state,payload){
		  localStorage.setItem('informationTab',JSON.stringify(payload))
		  state.informationTab = payload;
	  }
  },
  actions: {
	  
  },
  modules: {
	  
  }
})
