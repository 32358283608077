import Vue from 'vue';
import wx from 'weixin-js-sdk'
import apiActions from './api.js';
import { Toast } from 'vant';
import {zmitiConfig} from './config'
export  default {
	H5API:zmitiConfig.H5API,
	pageHost:zmitiConfig.pageHost,
	wxpageHost:zmitiConfig.wxpageHost,
	wx:wx,
	serverProduct:zmitiConfig.serverProduct,
	countDown(that,type) { //发送验证码
		if (!that.canClick) return
		if (that.zhDl.usermobile == "") {
			return false;
		} else if (!/^[1][0-9]{10}$/.test(that.zhDl.usermobile)) {
	
			return false;
		}
		this.mobileCode({
			mobile:that.zhDl.usermobile,
			type:type
		}); //获取验证码
		that.canClick = false;
		that.codecontent = that.totalTime + 's后重新发送'
		let clock = window.setInterval(() => {
			that.totalTime--
			that.codecontent = that.totalTime + 's后重新发送'
			if (that.totalTime < 0) {
				window.clearInterval(clock)
				that.codecontent = '重新发送验证码'
				that.totalTime = 60
				that.canClick = true //这里重新开启
			}
		}, 1000)
	},
	mobileCode(val){/*发送验证码接口*/
		apiActions.adminGeneral.mobileCode.http({
			mobile: val.mobile,
			type: val.type
		}).then(res => {
			Toast(res.msg)
		})
	},
	getQueryString(name) {
		var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)", "i");
		var r = window.location.search.substr(1).match(reg);
		if (r != null && r[2] != "false")
			return unescape(r[2]);
		return false;
	},
	getCaption(url, parameter) {/*获取url后所有的参数*/
		var index = url.lastIndexOf(parameter);
		url = url.substring(index + 1, url.length);
		return url;
	},
	DateFormatter(t, formatter) {
		var time = this.dataTim(t)
		formatter = formatter ? formatter : 'yyyy-MM-dd';
		let year = time.getFullYear();
		let month = time.getMonth() + 1;
		let day = time.getDate();
		let hh = time.getHours();
		let mm = time.getMinutes();
		let ss = time.getSeconds();
		if (month <= 9) month = '0' + month;
		if (day <= 9) day = '0' + day;
		if (hh <= 9) hh = '0' + hh;
		if (mm <= 9) mm = '0' + mm;
		if (ss <= 9) ss = '0' + ss;
		return formatter.replace(/yyyy/i, year).replace('MM', month).replace('dd', day).replace(/HH/i, hh).replace('mm',
				mm)
			.replace('ss', ss);
	},
	dataTim(time) {
		if (time == null || time == '' || time == undefined)
			return null;
		try {
			//解决ios手机时间格式化NAN问题
			if (typeof(time) == "string" && time.indexOf('-') != -1) {
				time = time.replace(/-/g, '/');
			}
			time = new Date(time);
		} catch (e) {
			time = time;
		}
		return time
	},
	formatDate(type,value) {
	    let date = new Date(value*1000);
	    let y = date.getFullYear();
	    let MM = date.getMonth() + 1;
	    MM = MM < 10 ? ('0' + MM) : MM;
	    let d = date.getDate();
	    d = d < 10 ? ('0' + d) : d;
	    let h = date.getHours();
	    h = h < 10 ? ('0' + h) : h;
	    let m = date.getMinutes();
	    m = m < 10 ? ('0' + m) : m;
	    let s = date.getSeconds();
	    s = s < 10 ? ('0' + s) : s;
	    let onlyM=date.getMonth() + 1;
	    let onlyD=date.getDate();
		var timestr='';
		switch(type){
			case 'yy.MM.dd':
				timestr= y + '.' + MM + '.' + d
				break;
			case 'yy-MM-dd':
				timestr= y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
				break;
			case 'MM-dd HH:mm:ss':
				timestr= MM + '-' + d + ' ' + h + ':' + m + ':' + s
				break;
			case 'HH:mm':
				timestr= h + ':' + m
				break;
			case 'cn':
				timestr= y + '年' + MM + '月' + d + '日 ' + h + ':' + m
				break;
			case 'zh':
				timestr= y + '年' + MM + '月' + d+ '日'
				break;
			case 'en':
				timestr= y + '/' + MM + '/' + d
				break;
			case 'mm':
				timestr= onlyM
				break;
			case 'dd':
				timestr= onlyD
				break;
			default:
				timestr= y + '-' + MM + '-' + d
		}
		return timestr
	    
	},
	formatimestamp:function(timestamp){//获取时间戳
		let timeval=''
		if(timestamp!=''){
			timeval=Date.parse(timestamp);
		}
		return timeval
	},
	nations:["汉族", "蒙古族", "回族", "藏族", "维吾尔族", "苗族", "彝族", "壮族", "布依族", "朝鲜族",
	"满族", "侗族", "瑶族", "白族", "土家族", "哈尼族", "哈萨克族", "傣族", "黎族", "傈僳族",
	"佤族", "畲族", "高山族", "拉祜族", "水族", "东乡族", "纳西族", "景颇族", "柯尔克孜族", "土族",
	"达斡尔族", "仫佬族", "羌族", "布朗族", "撒拉族", "毛难族", "仡佬族", "锡伯族", "阿昌族", "普米族",
	"塔吉克族", "怒族", "乌孜别克族", "俄罗斯族", "鄂温克族", "崩龙族", "保安族", "裕固族", "京族", "塔塔尔族",
	"独龙族", "鄂伦春族", "赫哲族", "门巴族", "珞巴族", "基诺族"],
	str:function(msg,len){//限制文字长度
		let value= msg.replace(/<[^<>]+>/g,'')
		if (value && value.length > len) {
			value = value.substring(0, len) + "...";
		}	    
		return value
	},
	imgstr:function(imgurl){//获取信息管理图片
		let imgArr=imgurl.split(',')
		return this.picurl+''+imgArr[0]
	},
	faceicon:function(icon){
		let iconval='huabanfuben'
		for(var i=0;i<faceDatalist.length;i++){
			if(icon==faceDatalist[i].code){
				iconval=faceDatalist[i].class
			}
		}
		return iconval
	},
	getImgInfo (url) {//获取图片宽高
		return new Promise((resolve, reject) => {
			let img = new Image();
			img.src = url;
			img.onload = function () {
				resolve({
					width: img.width,
					height: img.height
				});
			};
			img.onerror = function () {
				reject(new Error("图片加载错误！"));
			}
		});
	},
	imgstyle:function(newimg){//方形图片重置样式
		//console.log(newimg,'获取的图片的信息')
		let imgwidth=newimg.width
		let imgtop=0
		let imgleft=0
		let imgheight=newimg.height
		let imgstyle={}	
	
		//console.log(newimg.width,newimg.height,'图片的宽度和高度')
			if(newimg.width>newimg.height && newimg.width>0 && newimg.height>0){
				let scaleA=114/newimg.height
				imgwidth=parseInt(imgwidth*scaleA)
				imgheight=parseInt(imgheight*scaleA)
				imgleft=parseInt(~(imgwidth-imgheight)/2)
	
				imgstyle={
					width:imgwidth+'px',
					height:imgheight+'px',
					left:imgleft+'px'
				}
			}else if(newimg.width<newimg.height){
				let scaleB=114/newimg.width
				imgwidth=parseInt(imgwidth*scaleB)
				imgheight=parseInt(imgheight*scaleB)
				imgtop=parseInt(~(imgheight-imgwidth)/2)
	
				imgstyle={
					width:imgwidth+'px',
					height:imgheight+'px',
					top:imgtop+'px'
				}
			}else{
				let scaleC=114/newimg.width
				imgwidth=parseInt(imgwidth*scaleC)
				imgheight=parseInt(imgheight*scaleC)
	
				imgstyle={
					width:imgwidth+'px',
					height:imgheight+'px'
				}
			}
		return imgstyle		
	},
	objectArraySort(keyName) {//数组排序
		return function (objectN, objectM) {
			var valueN = objectN[keyName]
			var valueM = objectM[keyName]
			if (valueN < valueM) return 1
			else if (valueN > valueM) return -1
			else return 0
		}
	},
	objectArrayAsc(keyName) {//数组升序
		return function (objectN, objectM) {
			var valueN = objectN[keyName]
			var valueM = objectM[keyName]
			if (valueN < valueM) return 1
			else if (valueN > valueM) return +1
			else return 0
		}
	},
	arrayAsc(points){//数组数字升序
		return points.sort(function(a, b){return a - b});	
	},
	getSurplus(endtime){//还有几天
		var s2=endtime*1000
		var s1=new Date().getTime()
		var runTime = parseInt((s2 - s1) / 1000);
	    var apartDay = Math.floor(runTime / 86400);
		return apartDay
	},
	checkvalue(options,values){//获取选中后的值
		//options有label和value，values为选中后的数组且只有索引
		let checkArr=[];
		let checkStr='';
		if(options.constructor===Array){
		  values.map((item,index)=>{
			if(item==options[index].value){
			  checkArr.push(options[index].label)
			}
		  })
		  checkStr=checkArr.join(',');
		}
		return checkStr
	},
	eachvalue(options,values){//获取多选的值
		//options为多选的索引values为数据源
		let checkArr=[];
		let checkStr='';
		if(options.constructor===Array){
			values.map((item,index)=>{
				options.map((ele,idx)=>{
					if(index==ele){
						checkArr.push(item)
					}
				})
				
			})
			checkStr=checkArr.join(',');
		}
		return checkStr
	},
	sensitiveWordsType (val, val1) {
		var obj = (val1 || []).filter(item => {
			var reg = new RegExp(item.text, 'g');
			return val.match(reg)
		});
		return obj;
	}
}