import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home'
import store from '../store/index.js'

Vue.use(VueRouter)

const Router = new VueRouter({
	routes: [{
			path: '/',
			name: 'Home',
			component: Home,
			meta: {
				auth: true,/*是否需要登录*/
				hidden: true,
				title: "智小媒",
				keepAlive: false,
			}
		},{
			path: '/information',
			name: 'Information',
			component: () => import( /* webpackChunkName: "Home" */ '../views/Home/information.vue'),
			meta: {
				auth: true,/*是否需要登录*/
				hidden: true,
				title: "资讯",
				keepAlive: false,
			}
		},{
			path: '/service',
			name: 'Service',
			component: () => import( /* webpackChunkName: "Home" */ '../views/Home/service.vue'),
			meta: {
				auth: true,/*是否需要登录*/
				hidden: true,
				title: "服务",
				keepAlive: false,
			}
		},{
			path: '/my',
			name: 'My',
			component: () => import( /* webpackChunkName: "Home" */ '../views/Home/my.vue'),
			meta: {
				auth: true,/*是否需要登录*/
				hidden: true,
				title: "个人中心",
				keepAlive: false,
			}
		},{
			path: '/personal',
			name: 'Personal',
			component: () => import( /* webpackChunkName: "Home" */ '../views/Home/personal.vue'),
			meta: {
				auth: true,/*是否需要登录*/
				hidden: true,
				title: "我的",
				keepAlive: false,
			}
		},{
			path: '/activity',
			name: 'Activity',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/Activity.vue'),
			meta: {
				auth: true,
				hidden: true,
				title: "活动",
				keepAlive: false,
			}
		},{
			path: '/evaluationStar',
			name: 'EvaluationSstar',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/Evaluation_star.vue'),
			meta: {
				auth: true,
				hidden: true,
				title: "评星",
				keepAlive: false,
			}
		},{
			path: '/other',
			name: 'Other',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/Other.vue'),
			meta: {
				auth: true,
				hidden: true,
				title: "其他",
				keepAlive: false,
			}
		},{
			path: '/survey',
			name: 'Survey',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/Survey.vue'),
			meta: {
				auth: true,
				hidden: true,
				title: "调查",
				keepAlive: false,
			}
		},{
			path: '/vote',
			name: 'Vote',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/Vote.vue'),
			meta: {
				auth: true,
				hidden: true,
				title: "投票",
				keepAlive: false,
			}
		},{
			path: '/details/:productid?/:companyid?/:real?/:title?/:id?/:type?',
			name: 'Details',
			component: () => import( /* webpackChunkName: "HuoDongJu" */ '../views/HuoDongJu/Details.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "详情",
				keepAlive: false,
			}
		},{
			path: '/cyhome',
			name: 'HomeCy',
			component: () => import( /* webpackChunkName: "ChangYue" */ '../views/ChangYue'),
			meta: {
				auth: true,
				hidden: true,
				title: "畅阅",
				keepAlive: false,
			}
		},{
			path: '/cycomments/:manuscriptid?',
			name: 'CommentsCy',
			component: () => import( /* webpackChunkName: "ChangYue" */ '../views/ChangYue/comments.vue'),
			meta: {
				auth: true,
				hidden: true,
				title: "评论",
				keepAlive: false,
			}
		},{
			path: '/mycomments',
			name: 'MyComments',
			component: () => import( /* webpackChunkName: "ChangYue" */ '../views/ChangYue/myComments.vue'),
			meta: {
				auth: true,
				hidden: true,
				title: "我的评论",
				keepAlive: false,
			}
		},{
			path: '/cystatistical/:productid?',
			name: 'StatisticalCy',
			component: () => import( /* webpackChunkName: "ChangYue" */ '../views/ChangYue/statistical.vue'),
			meta: {
				auth: true,
				hidden: true,
				title: "统计",
				keepAlive: false,
			}
		}, {
			path: '/login',
			name: 'Login',
			component: () => import( /* webpackChunkName: "Login" */ '../views/Login'),
			meta: {
				auth: false,
				hidden: false,
				title: "登录",
				keepAlive: false
			}
		}, {
			path: '/wxIndex',
			name: 'Wxindex',
			component: () => import( /* webpackChunkName: "Login" */ '../views/Login/wxIndex.vue'),
			meta: {
				auth: false,
				hidden: false,
				title: "微信授权登录",
				keepAlive: false
			}
		},{
			path: '/modify/:type?',
			name: 'Modify',
			component: () => import( /* webpackChunkName: "Login" */ '../views/Login/modify.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "修改密码",
				keepAlive: false,
			}
		},{
			path: '/registered',
			name: 'Registered',
			component: () => import( /* webpackChunkName: "Login" */ '../views/Login/registered.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "注册",
				keepAlive: false,
			}
		},{
			path: '/detail/:id?/:companyid?/:productid?',
			name: 'Detail',
			component: () => import( /* webpackChunkName: "XinWen" */ '../views/XinWen/detail.vue'),
			meta: {
				auth: false,
				hidden: true,
				title: "详情",
				keepAlive: false,
			}
		}
	]
})
/*路由全局拦截*/
Router.beforeEach(function(to, from, next) {
	if (to.meta.title) {
		document.title = to.meta.title
	} else {
		document.title = '智小媒'
	}
	if (to.matched.length ===0) {/*如果未匹配到路由*/
	    from.name ? next('/'+from.name.toLowerCase()) : next('/');   /*如果上级也未匹配到路由则跳转首页页面，如果上级能匹配到则转上级路由*/
	  } else {
	    //需求登录判断
	    if (to.meta.auth) {
			/*不为空*/
	    	if (store.state.user.token != '' && store.state.user.userid) {
	    		next();
	    	} else {
	    		next('/login');
	    		// 这里与上面的相对应，此处也可以写成
	    		/***  next({
	    		     path: '/login',
	    		      query: { redirect: to.fullPath }
	    		  })***/
	    		//上面为另外一种写法
	    	}
	    } else {
	    	next();
	    }
	  }
	
})
/*路由重写*/
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
	return originalPush.call(this, location).catch(err => err)
}
export default Router
